import AWSAppSyncClient from 'aws-appsync';
import VueApollo from 'vue-apollo';
import { getAccessToken } from '@/lib/auth';
import * as localForage from 'localforage';
import { getConfigEnv } from '@/utils/helpers';

const client = new AWSAppSyncClient({
  url: getConfigEnv('MAP_LAYERS_ENDPOINT_URL'),
  region: getConfigEnv('APPSYNC_REGION'),
  auth: {
    type: 'AMAZON_COGNITO_USER_POOLS',
    jwtToken: async () => getAccessToken(),
  },
  offlineConfig: {
    storage: localForage,
    keyPrefix: 'mapLayers',
  },
}, {
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

const appSyncProvider = new VueApollo({
  defaultClient: client,
});
const predictionsApolloClient = appSyncProvider.defaultClient;

export default predictionsApolloClient;
